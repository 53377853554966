import React, { useState, useEffect } from 'react';
import { Spinner, Card, Col, Row } from 'react-bootstrap';
import noImagen from '../../../../images/no-image.jpg';
import { LinkPrimary, LinkSecondary } from '../components/CitoPlusLink';
import axiosInstance from '../../../../services/AxiosInstance';
import { useVigilanteReloadContext } from "../../../../context/VisitantesVigilantesContext";
import { notifyError, notifySuccess } from '../components/CitoPlusToastr';
import registro_visitas from '../../../../images/registro_visitante_transparente.png';
import Comentarios from '../components/sidebar_comments';
import _ from 'lodash';

const VisitantesPeatonales = () => {
  const { reloadData, setReloadData } = useVigilanteReloadContext();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const URL = '/api/v3/visitantes/ingreso-peatonal/sin_salida/';
  const URL_NOTAS = '/api/v3/vigilancia/visitantes-comentarios/';
  const [loadingRows, setLoadingRows] = useState({});
  const [filterText, setFilterText] = useState('');
  const [filterDate, setFilterDate] = useState({
    date_after: '',
    date_before: ''
  });
  const [showSidebarComments, setShowSidebarComments] = useState(false);
  const [selectedComentarios, setSelectedComentarios] = useState([]);
  const [selectedPrePostData, setSelectedPrePostData] = useState({});
  const [urlComentariosParams, setUrlComentariosParams] = useState({});

  const fetchUrl = async (loadMore = false) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(URL, {
        params: {
          page: loadMore ? page + 1 : 1,
          search: filterText,
          ...filterDate
        }
      });
      setNextUrl(response.data.next);
      setData(loadMore ? [...data, ...response.data.results] : response.data.results);
      if (loadMore) setPage(prevPage => prevPage + 1);
    } catch (error) {
      notifyError('Error al cargar los datos');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUrl();
  }, []);

  useEffect(() => {
    if (reloadData.visitantes) {
      fetchUrl();
      setReloadData(prev => ({ ...prev, visitantes: false }));
    }
  }, [reloadData.visitantes]);

  const handleSalidaVisitante = (slug) => async () => {
    try {
      setLoadingRows(prev => ({ ...prev, [slug]: true }));
      await axiosInstance.patch(`/api/v3/visitantes/ingreso-peatonal/${slug}/`, { 'ingreso': false });
      fetchUrl();
      notifySuccess('✔️ La salida fue exitosa!');
    } catch (error) {
      notifyError('❌ Hubo un error al procesar la salida del visitante!');
    } finally {
      setLoadingRows(prev => ({ ...prev, [slug]: false }));
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    fetchUrl();
  };

  const handleLoadMore = () => {
    if (nextUrl) {
      fetchUrl(true);
    }
  };

  const handleComentariosClick = (value, slug) => {
    setSelectedPrePostData({
      visitante: slug,
      nota: ''
    });
    setUrlComentariosParams({
      visitante__slug: slug,
      page_size: 100
    });
    setSelectedComentarios(value);
    setShowSidebarComments(true);
  };

  return (
    <>
      <div className='d-flex align-items-center mb-4 flex-wrap'>
        <h4 className='card-title'>Visitantes Peatonales</h4>
      </div>

      <div className="row mb-4">
        <div className="col-xl-12">
          <form className="row" onSubmit={handleSearch}>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar nombre o identificación..."
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <button
                type="submit"
                className="btn btn-primary w-100">
                <i className='fas fa-search me-2'></i>
                Buscar
              </button>
            </div>
          </form>
        </div>
      </div>

      {loading ? (
        <p>Cargando...</p>
      ) : data.length === 0 ? (
        <Col xl={12} className="text-center">
          <img src={registro_visitas} alt="No hay visitantes pendientes" className="img-fluid" width={"50%"} />
          <h3 className="mt-3">No hay Visitantes</h3>
        </Col>
      ) : (
        <>
          <Row>
            {data.map((item, index) => (
              <Col xl={6} key={index}>
                <Card>
                  <Card.Body>
                    <h4 className='fs-20'>{_.startCase(item.nombre)} ({item.identificacion})</h4>
                    <div className="listline-wrapper mb-4">
                      <span className="item">{item.unidad_visita}</span>
                    </div>
                    <p className="user d-sm-flex d-block pe-md-3 pe-0">
                      {item.foto && (
                        <a href={item.foto} data-lightbox={`image${index}`}>
                          <img
                            src={item.foto || noImagen}
                            alt=""
                            style={{ objectFit: "cover" }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = noImagen;
                            }}
                          />
                        </a>
                      )}
                      <div className='ms-sm-3 m3-md-5 md-0'>
                        <p>Autorizado: {_.startCase(item.autorizado)}</p>
                        <p>{_.toUpper(item.tipo_de_ingreso)}</p>
                      </div>
                    </p>
                  </Card.Body>
                  <Card.Footer className='d-flex justify-content-between align-items-center flex-wrap'>
                    <div className='mb-2'>
                      <i className='fas fa-clock me-2'></i>
                      {item.h_ingreso}
                    </div>
                    <div className='mb-2'>
                      <LinkSecondary
                        href="#"
                        onClick={() => handleComentariosClick(item.comentarios, item.slug)}
                        title="Comentarios"
                      >
                        <i className="fa-solid fa-comments me-2"></i>
                        ({item.total_comentarios})
                      </LinkSecondary>
                      <LinkPrimary
                        href="#"
                        className={`position-relative ${loadingRows[item.slug] ? 'disabled' : ''}`}
                        onClick={handleSalidaVisitante(item.slug)}
                      >
                        {loadingRows[item.slug] ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <>
                            <i className="fas fa-person-walking me-2"></i>
                            Salida
                          </>
                        )}
                      </LinkPrimary>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
          {nextUrl && (
            <button className="btn load-more-btn w-100 mt-3" onClick={handleLoadMore}>
              <i className="fas fa-arrow-down me-2"></i> Cargar más...
            </button>
          )}
          <Comentarios
            showSidebarComments={showSidebarComments}
            setShowSidebarComments={setShowSidebarComments}
            urlComentarios={URL_NOTAS}
            urlComentariosParams={urlComentariosParams}
            selectedPrePostData={selectedPrePostData}
          />
        </>
      )}
    </>
  );
}

export default VisitantesPeatonales;
